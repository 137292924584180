//para mostrar cuando se acceda a paginas que no existen dentro del sitio
import React from "react"
import Layout from "../components/layout-component"
import styles from "../css/error.module.css"
//import { Link } from "gatsby"

import Banner from "../components/banner-component"
import { Link, FormattedMessage } from "gatsby-plugin-intl"
const error = () => {
  return (
    <Layout>
      <header className={styles.error}>
        <Banner title={<FormattedMessage id="errorMessage" />}>
          <Link to="/" className="btn-white">
            <FormattedMessage id="errorMessageButton" />
          </Link>
        </Banner>
      </header>
    </Layout>
  )
}

export default error
